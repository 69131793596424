.Projects-container {
  padding: 10px 20px;
}

.Projects-title {
  text-align: start;
  font-size: 18px;
  color: #455a64;
}

.Projects-cards {
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (max-width: 610px) {
  .Projects-cards {
    justify-content: center;
  }
}
