.Tag-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #eceff1;
  width: 84px;
  height: 26px;
  border-radius: 13px 12px 12px 13px;
  margin: 0px 2px;
}

.Tag-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  background-color: #fff;
  border-radius: 13px;
}

.Tag-icon {
  width: 26px;
  height: 26px;
  box-sizing: border-box;
  padding: 4px;
}

.Tag-label {
  font-size: 12px;
  color: #455a64;
  margin-right: 6px;
}
