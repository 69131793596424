.App {
  text-align: center;
  background-color: #f5f5f5;
  min-height: 100vh;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Main-title {
  font-size: 36px;
  color: #455a64;
}
