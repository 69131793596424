.Card-container {
  width: 286px;
  height: 286px;
  background-color: #90a4ae;
  margin: 2px;
  transition: transform 0.2s linear;
  transition: box-shadow 0.2s linear;
}

.Card-container.Active:hover {
  transform: scale(1.01);
  box-shadow: #999 1px 1px 2px 0px;
}

.Card-title {
  color: #eceff1;
  text-align: center;
  font-size: 24px;
}

.Card-desc {
  color: #eceff1;
  text-align: center;
  font-size: 14px;
  margin-top: 62px;
  box-sizing: border-box;
  height: 57px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Card-tags {
  display: flex;
  padding: 0px 10px;
}
